import React from "react";
import Header from "../components/Header";
import Hero from "../components/NewHero";
import Problem from "./Problem";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import PricingTables from "./Pricing";
import Faqs from "./Faqs";
import Cta from "./Cta";
import { useState, useEffect } from "react";
import TopBanner from "./TopBanner";
import IframeResizer from "iframe-resizer-react";
import { motion } from "framer-motion";
import { ArrowRight, Zap, Sparkles, ChevronRight } from "lucide-react";

// You can switch between 'blue' and 'green' themes
// const theme = "blue"; // or 'green'
// const theme = "green"; // or 'green'

// const Banner = () => {
//   const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
//   const textColor = "text-white";
//   const linkColor = "text-white hover:text-white";

//   return (
//     <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
//       <div className="container mx-auto flex items-center justify-center">
//         <motion.p
//           className={`text-sm md:text-base font-normal ${textColor} tracking-wide`}
//           initial={{ y: 10, opacity: 0 }}
//           animate={{ y: 0, opacity: 1 }}
//           transition={{ delay: 0.2, duration: 0.5 }}
//         >
//           Introducing NeedFinderAI. Real-Time AI Market Research.
//           <motion.a
//             href="https://needfinderai.com"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={`ml-2 ${linkColor} font-bold hover:underline  underline transition-all duration-300`}
//             whileHover={{ x: 3 }}
//           >
//             Try it now &gt;
//           </motion.a>
//         </motion.p>
//       </div>
//     </motion.div>
//   );
// };

// const theme = "green"; // or 'blue'

// const Banner = () => {
//   const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
//   const textColor = "text-white";
//   const linkColor = "text-white hover:text-white";

//   // Countdown Timer Logic
//   const calculateTimeLeft = () => {
//     const targetDate = new Date();
//     targetDate.setDate(targetDate.getDate() + 7); // 7 days from now

//     const difference = +new Date(targetDate) - +new Date();
//     let timeLeft = {};

//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60),
//       };
//     }

//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearTimeout(timer);
//   });

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <span key={interval}>
//         {timeLeft[interval]} {interval}{" "}
//       </span>
//     );
//   });

//   return (
//     <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
//       <div className="container mx-auto flex items-center justify-center">
//         <motion.p
//           className={`text-sm md:text-base font-normal ${textColor} tracking-wide`}
//           initial={{ y: 10, opacity: 0 }}
//           animate={{ y: 0, opacity: 1 }}
//           transition={{ delay: 0.2, duration: 0.5 }}
//         >
//           🚀 Limited Time: Get <strong>50% OFF</strong> All Plans! Use Code: <strong>SAVE50NOW</strong>. Offer ends in{" "}
//           <strong>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</strong>!
//           <motion.a
//             href="https://needfinderai.com"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={`ml-2 ${linkColor} font-bold hover:underline underline transition-all duration-300`}
//             whileHover={{ x: 3 }}
//           >
//             Try it now &gt;
//           </motion.a>
//         </motion.p>
//       </div>
//     </motion.div>
//   );
// };
const styles = {
  fontFamily: "Inter, sans-serif",
};

const LandingPage = () => {
  return (
    <div style={styles} className="font-inter antialiased bg-white text-gray-900 tracking-tight ">
      <TopBanner />
      <Header />
      {/* Add the Banner component */}
      <div className="grow ">
        <Hero />

        <HowItWorks />
        <Problem />
        <PricingTables />

        <Faqs />
        <Cta />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
