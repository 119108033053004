// import React, { useState, useEffect } from "react";

// import MobileMenu from "../components/Mobile-menu";
// import { Link } from "react-router-dom";
// import Logo from "../images/thumbnail.png";
// import { NavLink } from "react-router-dom"; // Import NavLink

// export default function Header() {
//   const [top, setTop] = useState(true);

//   // detect whether user has scrolled the page down by 10px
//   const scrollHandler = () => {
//     window.pageYOffset > 10 ? setTop(false) : setTop(true);
//   };

//   useEffect(() => {
//     scrollHandler();
//     window.addEventListener("scroll", scrollHandler);
//     return () => window.removeEventListener("scroll", scrollHandler);
//   }, [top]);

//   // Function to render link with NavLink and apply active styles
//   const renderLink = (to, text) => (
//     <NavLink
//       to={to}
//       className={({ isActive }) =>
//         `bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ${
//           isActive ? "ring ring-green-500 ring-opacity-50" : ""
//         }`
//       }
//     >
//       {text}
//     </NavLink>
//   );

//   return (
//     <header
//       className={`sticky top-0 w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out bg-gray-50 ${
//         !top ? " backdrop-blur-sm " : ""
//       }`}
//     >
//       <div className="max-w-6xl mx-auto px-5 sm:px-6">
//         <div className="flex items-center justify-between h-16 md:h-20">
//           {/* Site branding */}
//           <div className="shrink-0 mr-4">
//             <Link
//               to="/"
//               className="flex items-center text-2xl font-bold text-gray-800"
//             >
//               <img src={Logo} alt="Logo" className="h-10 mr-2" />
//               <span className="font-semibold text-green-600 h-8">
//                 FeedbackbyAI
//               </span>
//             </Link>
//           </div>
//           {/* ... other elements ... */}

//           {/* Desktop navigation */}
//           <nav className="hidden md:flex md:grow">
//             {/* Desktop menu links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               {/* <li className="mx-2">
//                 {renderLink("/feedback/", "AI Character Feedback")}
//               </li>
//               <li className="mx-2">
//                 {renderLink("/feedback-by-astrology/", "Corporate Astrology")}
//               </li>
//               <li className="mx-2">
//                 {renderLink("/it-development/", "IT Development")}
//               </li>
//               <li className="mx-2">
//                 {renderLink("/credits/", "Credits & Recharge")}
//               </li> */}
//               {/* <li className="mx-2">
//                 <a
//                   href="mailto:business@feedbackbyai.com"
//                   className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow"
//                 >
//                   Contact Us
//                 </a>
//               </li> */}
//             </ul>

//             {/* Desktop sign in links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               <li>
//                 <a
//                   href="/signin/"
//                   className="btn-sm text-gray-200   bg-green-600 hover:bg-green-700 ml-3"
//                 >
//                   <span>Sign in</span>
//                   <svg
//                     className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1"
//                     viewBox="0 0 12 12"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
//                       fillRule="nonzero"
//                     />
//                   </svg>
//                 </a>
//               </li>
//             </ul>
//           </nav>

//           <MobileMenu />
//         </div>
//       </div>
//     </header>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MobileMenu from "../components/Mobile-menu";
import Logo from "../images/thumbnail.png";

export default function Header() {
  const [top, setTop] = useState(true);

  // Detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    setTop(window.pageYOffset <= 10);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  // Function to render a link with NavLink and apply active styles
  const renderLink = (to, text) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `px-4 py-2 rounded-full text-sm font-medium transition-all duration-150 ease-in-out ${
          isActive ? "bg-green-500 text-white shadow-lg" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
        }`
      }
    >
      {text}
    </NavLink>
  );

  return (
    <header
      className={`sticky top-0 w-full z-30 transition duration-300 ease-in-out ${
        top ? "bg-transparent" : "bg-gradient-to-b from-green-50 to-white"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img src={Logo} alt="FeedbackbyAI Logo" className="h-10" />
              <span className="ml-3 text-2xl font-bold text-green-600">FeedbackbyAI</span>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-center space-x-6 items-center">
              {/* {renderLink("/feedback/", "AI Character Feedback")}

              {renderLink("/dashboard/", "100+ Page Report")}
              {renderLink("/credits/", "Credits & Recharge")} */}
            </ul>
            <ul className="flex items-center space-x-4">
              <li>
                <a href="https://needfinderai.com" className="text-gray-600 font-light underline hover:text-green-700">
                  NeedFinderAI: Your AI Cofounder
                </a>
              </li>
              <li>
                <Link to="/signin/" className="btn btn-sm text-white bg-green-600 hover:bg-green-700 shadow-lg">
                  Sign In
                  <svg className="w-4 h-4 ml-2 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </Link>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  );
}
