import { useState, useEffect } from "react";
import React from "react";
import { motion } from "framer-motion";

// const CountdownTimer = ({ targetDate }) => {
//   const calculateTimeLeft = () => {
//     const difference = +new Date(targetDate) - +new Date();
//     let timeLeft = {};
//     if (difference > 0) {
//       timeLeft = {
//         d: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         h: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         m: Math.floor(((difference / 1000) * 60) % 60),
//         s: Math.floor((difference / 1000) % 60),
//       };
//     }
//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);
//     return () => clearTimeout(timer);
//   });

//   return (
//     <span className="inline-flex items-center space-x-1">
//       {Object.entries(timeLeft).map(([key, value]) => (
//         <span key={key} className="inline-flex flex-col items-center bg-white bg-opacity-20 rounded px-1.5 py-0.5">
//           <span className="text-2xl font-bold tabular-nums leading-none">{value.toString().padStart(2, "0")}</span>
//           <span className="text-[10px] uppercase font-semibold leading-tight">{key}</span>
//         </span>
//       ))}
//     </span>
//   );
// };

// const TopBanner = () => {
//   const targetDate = new Date("September 23, 2024 23:59:59");

//   return (
//     <div className="bg-gradient-to-r from-green-600 to-green-700 text-white py-2 px-4 flex items-center justify-center overflow-hidden">
//       <div className="flex flex-wrap items-center justify-center gap-4 md:gap-6">
//         <span className="text-2xl font-bold whitespace-nowrap flex items-center">
//           <span className="text-4xl mr-1">🚀</span>
//           <span className="bg-white text-green-700 px-2 py-0.5 rounded-full">50% OFF</span>
//           <span className="ml-1">All Plans</span>
//         </span>
//         <div className="flex items-center">
//           <span className="text-base font-semibold mr-2">Use code:</span>
//           <span className="bg-white text-green-700 px-3 py-1 rounded-full font-bold text-lg tracking-wider hover:bg-opacity-90 transition-colors">
//             I3OTQ1OQ
//           </span>
//         </div>
//         <div className="flex items-center">
//           <span className="text-base font-semibold mr-2">Ends in:</span>
//           <CountdownTimer targetDate={targetDate} />
//         </div>
//       </div>
//     </div>
//   );
// };

// You can switch between 'blue' and 'green' themes
// const theme = "blue"; // or 'green'
const theme = "green"; // or 'green'

const TopBanner = () => {
  const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
  const textColor = "text-white";
  const linkColor = "text-white hover:text-white";

  return (
    <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <div className="container mx-auto flex items-center justify-center">
        <motion.p
          className={`text-sm md:text-base font-normal ${textColor} tracking-wide`}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Introducing NeedFinderAI. Your AI Cofounder!
          <motion.a
            href="https://needfinderai.com"
            target="_blank"
            rel="noopener noreferrer"
            className={`ml-2 ${linkColor} font-bold hover:underline  underline transition-all duration-300`}
            whileHover={{ x: 3 }}
          >
            Try it now &gt;
          </motion.a>
        </motion.p>
      </div>
    </motion.div>
  );
};

export default TopBanner;
