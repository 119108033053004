import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import smoothscroll from "smoothscroll-polyfill";

// Import your components here
import LandingPage from "./components/LandingPage";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import ResetPassword from "./components/ResetPassword";
import Report from "./components/Report";
import MiniReport from "./components/MiniReport";
import FeedbackAssistant from "./components/Feedback";
import Credits from "./components/Credits";
import ReportDashboard from "./components/ReportDashboard";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import ReportDemo from "./components/ReportDemo";
import MiniReportDemo from "./components/MiniReportDemo";
// import Astrology from "./components/Astrology";
// import ItDevelopment from "./components/ItDevelopmemt";
// import BusinessProfile from "./components/BusinessProfile";
import ReactGA from "react-ga";
import FirstArticle from "./components/blog/first_article";
// import PitchConnect from "./components/TBEMainPage";

const App = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const myIP = "202.142.86.144"; // Replace with your current IP address

  useEffect(() => {
    // kick off the polyfill!
    smoothscroll.polyfill();

    axios
      .get("https://ipinfo.io?token=54225501c526ff")
      .then((response) => {
        const { country, ip } = response.data;
        // if (["IN", "NG", "PK", "VN"].includes(country) && ip !== myIP) {
        if (["IN", "NG", "PK", "VN", "BD", "TZ", "IR", "KP"].includes(country) && ip !== myIP) {
          // if (["NG", "PK"].includes(country) && ip !== myIP) {
          setIsRestricted(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user location:", error);
      });

    // Track page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (isRestricted) {
    return (
      <div>
        Unfortunately, this service/website is not available in your country.
        <br />
        {/* If you think this is an error please contact business@feedbackbyai.com{" "} */}
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="/signin/" element={<SignIn />} />
        <Route path="/signup/" element={<SignUp />} />
        <Route path="/forgot-password/" element={<ForgotPassword />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        <Route path="/full-report/:reportId" element={<Report />} />
        <Route path="/mini-report/:reportId" element={<MiniReport />} />
        <Route path="/dashboard/" element={<ReportDashboard />} />
        <Route path="/previewx/" element={<ReportDemo />} />
        <Route path="/minipreview/" element={<MiniReportDemo />} />
        <Route path="/feedback" element={<FeedbackAssistant />} />
        <Route path="/credits/" element={<Credits />} />
        <Route path="/report/" element={<ReportDashboard />} />
        <Route path="/privacy/" element={<Privacy />} />
        <Route path="/terms/" element={<Terms />} />
        {/* <Route path="/feedback-by-astrology/" element={<Astrology />} />
        <Route path="/it-development/" element={<ItDevelopment />} />
        <Route path="/profile/" element={<BusinessProfile />} />
        <Route path="/connect/" element={<PitchConnect />} /> */}
        <Route path="/blog/how-to-validate-your-business-idea-10-best-frameworks/" element={<FirstArticle />} />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
};

export default App;
