// import React, { useState, useEffect } from "react";
// import jwtDecode from "jwt-decode";
// import LogoutButton from "./LogoutButton";
// import api from "./axiosConfig"; // Adjust the path as necessary
// import { Link } from "react-router-dom";
// import DashboardHeader from "./DashboardHeader";
// import { useLocation } from "react-router-dom";
// import { AiOutlineInfoCircle, AiOutlineEdit } from "react-icons/ai";
// import ScaledUpIdeas from "./ScaledUpIdeas";
// import ReportFooter from "./ReportFooter";
// import Modal from "react-modal";
// import { motion, AnimatePresence } from "framer-motion";

// // import TopBanner from "./TopBanner";

// const CustomModal = ({ isOpen, onRequestClose, content }) => {
//   const [isCopied, setIsCopied] = useState(false);

//   useEffect(() => {
//     if (isCopied) {
//       const timer = setTimeout(() => setIsCopied(false), 2000);
//       return () => clearTimeout(timer);
//     }
//   }, [isCopied]);

//   const handleCopy = async () => {
//     try {
//       await navigator.clipboard.writeText(content);
//       setIsCopied(true);
//     } catch (err) {
//       console.error("Failed to copy text: ", err);
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center"
//       overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
//       closeTimeoutMS={300}
//     >
//       <AnimatePresence>
//         {isOpen && (
//           <motion.div
//             initial={{ opacity: 0, scale: 0.95 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.95 }}
//             transition={{ duration: 0.3 }}
//             className="bg-white rounded-lg shadow-xl max-w-lg w-full mx-4"
//           >
//             <div className="p-6">
//               <h3 className="text-lg font-medium text-gray-900 mb-4">Full Input</h3>
//               <div className="mt-2 mb-4">
//                 <div className="text-sm text-gray-500 max-h-96 overflow-y-auto p-4 border rounded-md whitespace-pre-wrap bg-gray-50">
//                   {content}
//                 </div>
//               </div>
//               <div className="flex justify-end space-x-3">
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   type="button"
//                   className={`inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none
//                     ${isCopied ? "bg-green-600 hover:bg-green-700" : "bg-blue-600 hover:bg-blue-700"}
//                   `}
//                   onClick={handleCopy}
//                 >
//                   {isCopied ? "Copied!" : "Copy Full Text"}
//                 </motion.button>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   type="button"
//                   className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
//                   onClick={onRequestClose}
//                 >
//                   Close
//                 </motion.button>
//               </div>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </Modal>
//   );
// };

// const TopBanner = () => {
//   const endTime = new Date();
//   endTime.setHours(endTime.getHours() + 8); // Set 24 hours from now

//   return (
//     <div className="bg-green-700 text-white text-center py-1 px-2">
//       <p className="text-sm md:text-base font-normal inline-block mr-2">
//         {/* 🌟 New Feature Alert: Now you can Generate Report in 30+ Languages
//         ,Export Your Report as Docs & PDF ! 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage globally with new
//         customers, partners, investors, and collaborations through your virtual
//         booth! 🌍🚀 */}
//         {/* 🌟 Upcoming Feature : Showcase Your Business Globally! Engage customers,
//         secure partnerships, attract investors, and spark new collaborations
//         through Virtual Booths. 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage globally with new
//         customers, partners, investors, and collaborations through your virtual
//         booth! 🌍🚀 */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Tap into our
//         exclusive network of 5,000+ businesses before everyone else. 🔒🚀
//         <br />
//         Say goodbye to cold outreach. Warm intros close deals faster. */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀
//         <br />
//         Ditch cold outreach. Warm intros close deals faster. */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀
//         <br />
//         Ditch cold outreach. Warm intros close deals faster. */}
//         {/* Forget cold outreach, unlock warm introductions within our exclusive
//         5,000+ business network and accelerate your growth. - Coming Soon 🚀 */}
//         {/* 🌟 Land your dream clients and partnerships – unlock exclusive warm
//         introductions with 5,000+ businesses. Coming Soon 🚀 */}
//         {/* Connect with 5,000+ global businesses for dream clients and
//         partnerships. Unlock exclusive warm introductions. Coming Soon 🚀 */}
//         {/* 🌟 🌟 Get Ready: Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀
//         <br /> Ditch cold outreach. Warm intros close deals faster. */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀 */}
//         <br />
//         {/* Ditch cold outreach. Warm intros close deals faster. */}
//         {/* This is how you'll pitch in 2024 🚀 The Pitch Room. Coming soon! */}
//         {/* 🌟 Tired of endless networking events? The Pitch Room is coming to
//         streamline connections. 🚀 */}
//         {/* 🌟 The Pitch Room is coming soon. 🚀 */}
//         {/* 🌟 Coming Soon : The Pitch Room 🚀 */}
//         🌟 NeedFinderAI: Is Coming Soon! 🚀
//       </p>
//     </div>
//   );
// };

// export default function ReportDashboard() {
//   const location = useLocation();
//   const [businessIdea, setBusinessIdea] = useState("");
//   const [language, setLanguage] = useState("english"); // Default to English
//   const [isSubmitting, setIsSubmitting] = useState(false); // New state to manage loading state

//   const [latestReports, setLatestReports] = useState([]);
//   // const [businessIdea, setBusinessIdea] = useState("");
//   // const [businessIdea, setBusinessIdea] = useState(initialIdea);
//   const [questionCredits, setQuestionCredits] = useState(0);
//   const [message, setMessage] = useState("");
//   const [showRechargeLink, setShowRechargeLink] = useState(false);
//   const [isFetchingReports, setIsFetchingReports] = useState(false);
//   const [hasReports, setHasReports] = useState(false);
//   const [showTooltip1, setShowTooltip1] = useState(false);
//   const [showTooltip2, setShowTooltip2] = useState(false);
//   const [userFullReportCredits, setUserFullReportCredits] = useState(0); // State to store user full report credits
//   const [accessedFullReportFreePreview, setaccessedFullReportFreePreview] = useState(false); // State to whether accessed full report free preview
//   const [accessedAstroFreeConsultation, setaccessedAstroFreeConsultation] = useState(false); // State to whether accessed full report free preview

//   const [userMiniReportCredits, setUserMiniReportCredits] = useState(0); // State to store user full report credits
//   const [accessedMiniReportFreePreview, setaccessedMiniReportFreePreview] = useState(false); // State to whether accessed full report free preview
//   const [latestFullReports, setLatestFullReports] = useState([]);
//   const [latestMiniReports, setLatestMiniReports] = useState([]);
//   const [hasFullReports, setHasFullReports] = useState(false);
//   const [hasMiniReports, setHasMiniReports] = useState(false);
//   const [reportType, setReportType] = useState("full"); // 'full' or 'mini'
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedReport, setSelectedReport] = useState(null);
//   const [copiedIndex, setCopiedIndex] = useState(null);

//   const styles = {
//     fontFamily: "Inter, sans-serif",
//     container: {
//       backgroundColor: "#f3f4f6", // Soft background color
//       padding: "20px",
//       borderRadius: "8px",
//       boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Subtle shadow for depth
//     },
//     button: {
//       backgroundColor: "#4a56e2", // Modern button color
//       color: "white",
//       padding: "12px 20px",
//       borderRadius: "5px",
//       border: "none",
//       cursor: "pointer",
//       fontSize: "16px",
//       fontWeight: "600",
//       boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Button shadow for a 3D effect
//     },
//     textarea: {
//       borderColor: "#cbd5e1", // Soft border color
//       borderRadius: "5px",
//     },
//   };
//   const handleCopy = (content) => {
//     navigator.clipboard.writeText(content);
//     setCopiedIndex(true);
//     setTimeout(() => setCopiedIndex(false), 2000); // Reset after 2 seconds
//   };

//   const openModal = (report) => {
//     setSelectedReport(report);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedReport(null);
//   };

//   function renderReportsList(title, reports, isFetching, reportType) {
//     // Determine the link prefix based on report type
//     const linkPrefix = reportType === "full" ? "/full-report/" : "/mini-report/";

//     // Customize the no reports message based on report type
//     const noReportsMessage =
//       reportType === "full"
//         ? "No previous full reports found. Craft your first comprehensive analysis now!"
//         : "No previous mini reports found. Craft your first mini report now!";

//     return (
//       <div>
//         <h3 className="font-bold text-2xl text-gray-900 mb-6 mt-10">My Previous {title}</h3>
//         <p className="text-sm text-gray-500 mb-4 italic">
//           *Opening a new report deducts report credits automatically.Select the correct report if you have multiple reports.
//         </p>
//         {isFetching ? (
//           <p className="text-lg text-gray-700">Loading...</p>
//         ) : (
//           <ul className="list-none space-y-4">
//             {reports.length > 0 ? (
//               reports.map((report) => (
//                 <li key={report.report_id} className="border-b pb-4">
//                   <Link
//                     to={`${linkPrefix}${report.report_id}`}
//                     className="text-blue-500 hover:text-blue-600 transition-colors duration-300"
//                   >
//                     {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
//                   </Link>
//                   {report.input.length > 100 && (
//                     <button
//                       onClick={() => openModal(report)}
//                       className="ml-4 text-green-500 hover:text-green-700 transition-colors duration-300"
//                     >
//                       View More
//                     </button>
//                   )}
//                 </li>
//               ))
//             ) : (
//               <p className="text-gray-700">{noReportsMessage}</p>
//             )}
//           </ul>
//         )}
//       </div>
//     );
//   }

//   const handleReportTypeChange = (event) => {
//     setReportType(event.target.value);
//   };

//   useEffect(() => {
//     async function fetchReports() {
//       setIsFetchingReports(true);
//       try {
//         // Fetch Full Reports
//         const fullReportsResponse = await api.get("user-latest-report/");
//         if (fullReportsResponse.status === 200) {
//           setLatestFullReports(fullReportsResponse.data);
//           setHasFullReports(fullReportsResponse.data.length > 0);
//         }

//         // Fetch Mini Reports
//         const miniReportsResponse = await api.get("user-latest-mini-report/");
//         if (miniReportsResponse.status === 200) {
//           setLatestMiniReports(miniReportsResponse.data);
//           setHasMiniReports(miniReportsResponse.data.length > 0);
//         }
//       } catch (error) {
//         console.error("Error fetching reports:", error);
//       } finally {
//         setIsFetchingReports(false);
//       }
//     }

//     fetchReports();
//   }, []);

//   useEffect(() => {
//     async function fetchUserCredits() {
//       try {
//         const response = await api.get("get-user-credits/"); // Replace with actual API endpoint
//         setUserFullReportCredits(response.data.report_credits); // Assume response contains credit info
//         setUserMiniReportCredits(response.data.mini_report_credits);
//         setQuestionCredits(response.data.question_credits);
//         setaccessedMiniReportFreePreview(response.data.accessed_minireport_free_preview);
//         setaccessedAstroFreeConsultation(response.data.accessed_astro_free_consultation);
//         setaccessedFullReportFreePreview(response.data.accessed_report_free_preview);
//         // console.log("user credits", response.data.report_credits);
//       } catch (error) {
//         // console.error("Error fetching user credits:", error);
//       }
//     }

//     fetchUserCredits();
//     // fetchReports();
//   }, []);

//   // async function handleSubmit(e) {
//   //   e.preventDefault();
//   //   setMessage("");
//   //   setShowRechargeLink(false);
//   //   setIsSubmitting(true); // Start loading state

//   //   // Check for available credits based on report type
//   //   // if (reportType === "full") {
//   //   //   // if (userFullReportCredits <= 0 && accessedFullReportFreePreview) {
//   //   //     if (userFullReportCredits <= 0 && accessedFullReportFreePreview) {
//   //   //     setMessage("Not enough full report credits.");
//   //   //     setShowRechargeLink(true);
//   //   //     return;
//   //   //   }
//   //   // } else {
//   //   //   // reportType === "mini"
//   //   //   if (userMiniReportCredits <= 0 && accessedMiniReportFreePreview) {
//   //   //     setMessage("Not enough mini report credits.");
//   //   //     setShowRechargeLink(true);
//   //   //     return;
//   //   //   }
//   //   // }

//   //   // Set a timeout to alert the user after 15 seconds
//   //   const timeoutId = setTimeout(() => {
//   //     alert(
//   //       "Submission is taking longer than expected. Please reload the page and try again."
//   //     );
//   //     setIsSubmitting(false); // Reset submitting state
//   //   }, 15000); // 15 seconds

//   //   // Show confirmation before deducting credits
//   //   const confirmReport = window.confirm(
//   //     "This will deduct one report credit. Please make sure content of your previous business ideas are fully generated."
//   //   );
//   //   if (!confirmReport) {
//   //     return;
//   //   }

//   //   // Define endpoint based on report type
//   //   const endpoint =
//   //     reportType === "full" ? "create-report/" : "create-mini-report/";

//   //   // Submit the form data
//   //   try {
//   //     const payload = {
//   //       business_idea_input: businessIdea,
//   //       language, // Include the selected language in the payload
//   //     };

//   //     const response = await api.post(endpoint, payload);
//   //     clearTimeout(timeoutId); // Clear the timeout if the request completes

//   //     if (response.status === 200 || response.status === 201) {
//   //       // Redirect to preview based on report type
//   //       if (reportType === "full") {
//   //         window.location.href = `/full-report/${response.data.report_id}`;
//   //       } else {
//   //         window.location.href = `/mini-report/${response.data.report_id}`;
//   //       }
//   //     }
//   //   } catch (error) {
//   //     clearTimeout(timeoutId); // Clear the timeout in case of error
//   //     if (error.response) {
//   //       const errorMessage = error.response.data.message;
//   //       setMessage(
//   //         errorMessage || "Something went wrong. Please try again later."
//   //       );
//   //       if (errorMessage.includes("Not enough credits")) {
//   //         setShowRechargeLink(true);
//   //       }
//   //     } else {
//   //       setMessage("An unexpected error occurred.");
//   //     }
//   //     console.error("Failed to submit form:", error.response?.data);
//   //   } finally {
//   //     // Ensure to disable the loading state after a certain timeout or immediately after receiving the response
//   //     setTimeout(() => setIsSubmitting(false), 30000); // Disable the loading state after 30 seconds as a fallback
//   //   }
//   // }

//   // Inside the ReportDashboard component

//   async function handleSubmit(e) {
//     e.preventDefault();
//     setMessage("");
//     setShowRechargeLink(false);
//     setIsSubmitting(true); // Start loading state

//     // Set a timeout to alert the user after 15 seconds
//     const timeoutId = setTimeout(() => {
//       alert("Submission is taking longer than expected. Please reload the page and try again.");
//       setIsSubmitting(false); // Reset submitting state
//     }, 10000); // 10 seconds

//     // Show confirmation before deducting credits
//     const confirmReport = window.confirm(
//       "This will deduct one report credit. Please make sure content of your previous business ideas are fully generated."
//     );
//     if (!confirmReport) {
//       clearTimeout(timeoutId); // Clear the timeout if the user cancels
//       setIsSubmitting(false); // Reset submitting state
//       return;
//     }

//     // Define endpoint based on report type
//     const endpoint = reportType === "full" ? "create-report/" : "create-mini-report/";

//     // Simulate a delay for testing purposes
//     // await new Promise((resolve) => setTimeout(resolve, 12000)); // Simulate 12 seconds delay

//     // Submit the form data
//     try {
//       const payload = {
//         business_idea_input: businessIdea,
//         language, // Include the selected language in the payload
//       };

//       const response = await api.post(endpoint, payload);
//       clearTimeout(timeoutId); // Clear the timeout if the request completes

//       if (response.status === 200 || response.status === 201) {
//         // Redirect to preview based on report type
//         if (reportType === "full") {
//           window.location.href = `/full-report/${response.data.report_id}`;
//         } else {
//           window.location.href = `/mini-report/${response.data.report_id}`;
//         }
//       }
//     } catch (error) {
//       clearTimeout(timeoutId); // Clear the timeout in case of error
//       if (error.response) {
//         const errorMessage = error.response.data.message;
//         setMessage(errorMessage || "Something went wrong. Please try again later.");
//         if (errorMessage.includes("Not enough credits")) {
//           setShowRechargeLink(true);
//         }
//       } else {
//         setMessage("An unexpected error occurred.");
//       }
//       console.error("Failed to submit form:", error.response?.data);
//     } finally {
//       setIsSubmitting(false); // Ensure to reset the submitting state
//     }
//   }

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const ideaKey = searchParams.get("ideaKey");

//     if (ideaKey) {
//       const storedIdeaData = localStorage.getItem(ideaKey);

//       if (storedIdeaData) {
//         const { storedIdea, timestamp } = JSON.parse(storedIdeaData);
//         // console.log("Retrieved idea and timestamp:", storedIdea, timestamp);

//         // Check if 60 minutes have passed since the data was stored
//         const currentTime = Date.now();
//         const minutesPassed = (currentTime - timestamp) / 60000; // Convert milliseconds to minutes

//         if (minutesPassed < 60) {
//           // Less than 60 minutes have passed, so use the stored idea
//           setBusinessIdea(storedIdea);
//         } else {
//           // More than 60 minutes have passed, remove the item from localStorage
//           localStorage.removeItem(ideaKey);
//           // console.log("Stored data expired and removed.");
//         }
//       }
//     }
//   }, [location]);
//   return (
//     <div className="font-inter antialiased bg-gradient-to-br from-green-50 via-green-100 to-green-50 text-gray-900 tracking-tight min-h-screen">
//       <TopBanner />
//       <DashboardHeader />
//       <main className="py-8 px-4 sm:px-6 lg:px-8">
//         <div className="max-w-4xl mx-auto">
//           <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-green-800 text-center mb-6 transition-all duration-300 ease-in-out transform hover:scale-105">
//             Analyze My Business Idea
//           </h1>
//           <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

//           <div className="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 ease-in-out transform hover:shadow-2xl mb-12">
//             <div className="p-6 sm:p-10">
//               {message && (
//                 <div
//                   className={`mb-6 p-4 rounded-lg ${
//                     showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
//                   }`}
//                 >
//                   <p className="text-sm font-medium text-gray-800">{message}</p>
//                   {showRechargeLink && (
//                     <Link
//                       to="/credits/"
//                       className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold transition-colors duration-200"
//                     >
//                       Recharge credits →
//                     </Link>
//                   )}
//                 </div>
//               )}

//               <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
//                 <h2 className="text-2xl font-bold text-green-800">Business Idea Analysis</h2>
//                 <Link
//                   to="/previewx/"
//                   className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-200"
//                 >
//                   View Sample Report
//                   <svg className="ml-2 -mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </Link>
//               </div>

//               <form onSubmit={handleSubmit} className="space-y-6">
//                 <div className="space-y-2">
//                   <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700">
//                     Describe your business idea
//                   </label>
//                   <textarea
//                     id="businessIdea"
//                     value={businessIdea}
//                     onChange={(e) => setBusinessIdea(e.target.value)}
//                     placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
//                     rows="7"
//                     className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200 ease-in-out"
//                     required
//                   />
//                 </div>

//                 <div className="space-y-2">
//                   <label htmlFor="language" className="block text-sm font-medium text-gray-700">
//                     Select Report Language
//                   </label>
//                   <div className="relative">
//                     <select
//                       id="language"
//                       value={language}
//                       onChange={(e) => setLanguage(e.target.value)}
//                       className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg transition-all duration-200 ease-in-out appearance-none bg-white"
//                     >
//                       <option value="english">English</option>
//                       <option value="spanish">Spanish</option>
//                       <option value="french">French</option>
//                       <option value="arabic">Arabic</option>
//                       <option value="russian">Russian</option>
//                       <option value="portuguese">Portuguese</option>
//                       <option value="indonesian">Indonesian</option>
//                       <option value="german">German</option>
//                       <option value="japanese">Japanese</option>
//                       <option value="swahili">Swahili</option>
//                       <option value="turkish">Turkish</option>
//                       <option value="korean">Korean</option>
//                       <option value="italian">Italian</option>
//                       <option value="vietnamese">Vietnamese</option>
//                       <option value="polish">Polish</option>
//                       <option value="ukrainian">Ukrainian</option>
//                       <option value="dutch">Dutch</option>
//                       <option value="greek">Greek</option>
//                       <option value="hebrew">Hebrew</option>
//                       <option value="thai">Thai</option>
//                       <option value="persian">Persian</option>
//                       <option value="czech">Czech</option>
//                       <option value="swedish">Swedish</option>
//                       <option value="romanian">Romanian</option>
//                       <option value="hungarian">Hungarian</option>
//                       <option value="danish">Danish</option>
//                       <option value="finnish">Finnish</option>
//                       <option value="slovak">Slovak</option>
//                       <option value="norwegian">Norwegian</option>
//                       <option value="serbian">Serbian</option>
//                       <option value="bulgarian">Bulgarian</option>
//                       <option value="mandarin">Mandarin</option>
//                     </select>
//                     <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                       <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
//                         <path
//                           fillRule="evenodd"
//                           d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </div>

//                 <button
//                   type="submit"
//                   disabled={isSubmitting}
//                   className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white text-lg font-semibold py-3 rounded-lg
//                     ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-green-600 hover:to-green-700"}
//                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 ease-in-out transform hover:-translate-y-1`}
//                 >
//                   {isSubmitting ? (
//                     <span className="flex items-center justify-center">
//                       <svg
//                         className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                       >
//                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                         <path
//                           className="opacity-75"
//                           fill="currentColor"
//                           d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                         ></path>
//                       </svg>
//                       Generating Your Report...
//                     </span>
//                   ) : (
//                     "Generate My Business Report!"
//                   )}
//                 </button>
//               </form>
//             </div>
//           </div>
//           <div className="bg-white rounded-lg shadow-md overflow-hidden">
//             <div className="p-6 sm:p-8">
//               <h2 className="text-2xl font-bold text-gray-800 mb-6">My Previous Full Reports</h2>
//               <p className="text-sm text-gray-600 mb-4">
//                 *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
//               </p>
//               {isFetchingReports ? (
//                 <div className="flex justify-center items-center h-24">
//                   <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
//                 </div>
//               ) : (
//                 <ul className="space-y-4">
//                   {latestFullReports.length > 0 ? (
//                     latestFullReports.map((report) => (
//                       <li key={report.report_id} className="border-b border-gray-200 pb-4">
//                         <Link to={`/full-report/${report.report_id}`} className="text-blue-600 hover:text-blue-800 font-medium">
//                           {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
//                         </Link>
//                         {report.input.length > 100 && (
//                           <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
//                             View More
//                           </button>
//                         )}
//                       </li>
//                     ))
//                   ) : (
//                     <p className="text-gray-600">No previous reports found. Generate your first comprehensive analysis now!</p>
//                   )}
//                 </ul>
//               )}
//             </div>
//           </div>
//         </div>
//       </main>
//       <ReportFooter />
//       <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import api from "./axiosConfig";
import DashboardHeader from "./DashboardHeader";
import ReportFooter from "./ReportFooter";
import Modal from "react-modal";
import { ChevronDown } from "lucide-react";
import TopBanner from "./TopBanner";

// const TopBanner = () => (
//   <div className="bg-green-800 text-white text-center py-2 px-4">
//     <p className="text-sm md:text-base font-medium">🌟 NeedFinderAI: Coming Soon! 🚀</p>
//   </div>
// );

// const theme = "blue"; // or 'green'
// const theme = "green"; // or 'green'

// const TopBanner = () => {
//   const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
//   const textColor = "text-white";
//   const linkColor = "text-white hover:text-white";

//   return (
//     <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
//       <div className="container mx-auto flex items-center justify-center">
//         <motion.p
//           className={`text-sm md:text-base font-light ${textColor} tracking-wide`}
//           initial={{ y: 10, opacity: 0 }}
//           animate={{ y: 0, opacity: 1 }}
//           transition={{ delay: 0.2, duration: 0.5 }}
//         >
//           Introducing NeedFinderAI. Real-Time AI Market Research.
//           <motion.a
//             href="https://needfinderai.com"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={`ml-2 ${linkColor} font-bold hover:underline  underline transition-all duration-300`}
//             whileHover={{ x: 3 }}
//           >
//             Try it now &gt;
//           </motion.a>
//         </motion.p>
//       </div>
//     </motion.div>
//   );
// };

const CustomModal = ({ isOpen, onRequestClose, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 z-50 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 p-6"
      >
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Full Input</h3>
        <div className="max-h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg mb-4">
          <p className="text-sm text-gray-700 whitespace-pre-wrap">{content}</p>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={handleCopy}
            className={`px-4 py-2 rounded-lg text-white font-medium transition-colors ${
              isCopied ? "bg-green-600" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isCopied ? "Copied!" : "Copy Full Text"}
          </button>
          <button
            onClick={onRequestClose}
            className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 font-medium hover:bg-gray-300 transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </Modal>
  );
};

export default function ReportDashboard() {
  const location = useLocation();
  const [businessIdea, setBusinessIdea] = useState("");
  const [language, setLanguage] = useState("english");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestFullReports, setLatestFullReports] = useState([]);
  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const [message, setMessage] = useState("");
  const [showRechargeLink, setShowRechargeLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    async function fetchReports() {
      setIsFetchingReports(true);
      try {
        const response = await api.get("user-latest-report/");
        if (response.status === 200) {
          setLatestFullReports(response.data);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setIsFetchingReports(false);
      }
    }

    fetchReports();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setMessage("");
    setShowRechargeLink(false);
    setIsSubmitting(true);

    const timeoutId = setTimeout(() => {
      alert("Submission is taking longer than expected. Please try again.");
      setIsSubmitting(false);
    }, 10000);

    const confirmReport = window.confirm("This will deduct one report credit. Are you sure you want to continue?");
    if (!confirmReport) {
      clearTimeout(timeoutId);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post("create-report/", {
        business_idea_input: businessIdea,
        language,
      });
      clearTimeout(timeoutId);

      if (response.status === 200 || response.status === 201) {
        window.location.href = `/full-report/${response.data.report_id}`;
      }
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage || "An error occurred. Please try again.");
        if (errorMessage.includes("Not enough credits")) {
          setShowRechargeLink(true);
        }
      } else {
        setMessage("An unexpected error occurred.");
      }
      console.error("Failed to submit form:", error.response?.data);
    } finally {
      setIsSubmitting(false);
    }
  }

  const openModal = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  return (
    <div className="font-sans bg-gradient-to-b from-green-50 to-white min-h-screen">
      <TopBanner />
      <DashboardHeader />
      <main className="max-w-4xl mx-auto px-4 py-12">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold text-green-800 text-center mb-8"
        >
          Analyze My Business Idea
        </motion.h1>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          {message && (
            <div
              className={`mb-6 p-4 rounded-lg ${
                showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
              }`}
            >
              <p className="text-sm font-medium text-gray-800">{message}</p>
              {showRechargeLink && (
                <Link to="/credits/" className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold">
                  Recharge credits →
                </Link>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700 mb-2">
                Describe your business idea
              </label>
              <textarea
                id="businessIdea"
                value={businessIdea}
                onChange={(e) => setBusinessIdea(e.target.value)}
                placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
                rows="7"
                className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                required
              />
            </div>

            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-2">
                Select Report Language
              </label>
              <div className="relative">
                <select
                  id="language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg appearance-none bg-white"
                >
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="arabic">Arabic</option>
                  <option value="russian">Russian</option>
                  <option value="portuguese">Portuguese</option>
                  <option value="indonesian">Indonesian</option>
                  <option value="german">German</option>
                  <option value="japanese">Japanese</option>
                  <option value="swahili">Swahili</option>
                  <option value="turkish">Turkish</option>
                  <option value="korean">Korean</option>
                  <option value="italian">Italian</option>
                  <option value="vietnamese">Vietnamese</option>
                  <option value="polish">Polish</option>
                  <option value="ukrainian">Ukrainian</option>
                  <option value="dutch">Dutch</option>
                  <option value="greek">Greek</option>
                  <option value="hebrew">Hebrew</option>
                  <option value="thai">Thai</option>
                  <option value="persian">Persian</option>
                  <option value="czech">Czech</option>
                  <option value="swedish">Swedish</option>
                  <option value="romanian">Romanian</option>
                  <option value="hungarian">Hungarian</option>
                  <option value="danish">Danish</option>
                  <option value="finnish">Finnish</option>
                  <option value="slovak">Slovak</option>
                  <option value="norwegian">Norwegian</option>
                  <option value="serbian">Serbian</option>
                  <option value="bulgarian">Bulgarian</option>
                  <option value="mandarin">Mandarin</option>
                  {/* Add more language options here */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown className="h-4 w-4" />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white text-lg font-semibold py-3 rounded-lg
                ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-green-600 hover:to-green-700"}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:-translate-y-1`}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating Your Report...
                </span>
              ) : (
                "Generate My Business Report!"
              )}
            </button>
          </form>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">My Previous Full Reports</h2>
          <p className="text-sm text-gray-600 mb-4">
            *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
          </p>
          {isFetchingReports ? (
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <ul className="space-y-4">
              {latestFullReports.length > 0 ? (
                latestFullReports.map((report) => (
                  <li key={report.report_id} className="border-b border-gray-200 pb-4">
                    <Link to={`/full-report/${report.report_id}`} className="text-blue-500 hover:text-green-800 font-medium">
                      {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
                    </Link>
                    {report.input.length > 100 && (
                      <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
                        View More
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <p className="text-gray-600">No previous reports found. Generate your first comprehensive analysis now!</p>
              )}
            </ul>
          )}
        </div>
      </main>
      <ReportFooter />
      <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />
    </div>
  );
}
